<template>
  <div class="no-sale-open-page">
    <img src="@/assets/404_images/noSaleCard.png" alt="">
    <el-button type="primary" class="btn" @click="logout">退出</el-button>
  </div>
</template>

<script>
import Storage from "@/utils/storage";

export default {
  name: "no-sale-open",
  methods: {
    logout() {
      this.$confirm("确定退出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let user = Storage.getItem("seller_user");
        let fromEnterSellerUser = JSON.parse(
          sessionStorage.getItem("from_enter_seller_user")
        );
        let member_type = (this.MixinIsFormEnterprise
            ? fromEnterSellerUser || user
            : user
        ).member_type;
        this.$store.dispatch("logoutAction").then(() => {
          switch (member_type) {
            case 2:
              window.location.href = "/login";
              break;
            case 3:
              window.location.href = "/login?type=enterprise";
              break;
            case 4:
              window.location.href = "/login?type=supplier";
              break;
            case 5:
              window.location.href = "/login?type=combo-card-shop";
              break;
            default:
              window.location.href = "/login";
              break;
          }
        });
      });
    },
  }
}
</script>

<style scoped lang="scss">
.no-sale-open-page {
  width: 100%;
  padding-top: 150px;
  text-align: center;
  img {
    display: block;
    width: 942px;
    height: 628px;
    margin: auto;
  }
  .btn {
    width: 140px;
    border-radius: 30px;
  }
}
</style>
